import { HOST, NOT_HOST } from "../config";

export const URLS = {
    VERSION: `${HOST}version`,
    LOGIN: `${HOST}reseller/login`,
    ADD_CREDIT: `${HOST}reseller/sub_reseller/credit/add`,
    TAKE_CREDIT: `${HOST}reseller/sub_reseller/credit/take`,
    CREDIT_HISTORY: `${HOST}reseller/credit_history`,
    CHECK_MAC: `${HOST}reseller/devices/check`,
    DEVICES: `${HOST}reseller/devices`,
    APPS: `${HOST}reseller/apps`,
    DEVICES_ACTIVATION: `${HOST}reseller/devices/activate`,
    PROFILE: `${HOST}reseller/profile`,
    PACKAGES: `${HOST}reseller/activation_packages`,
    RECAPCHA_SITE_KEY: `${HOST}key`,
    SUB_RESELLERS: `${HOST}reseller/sub_reseller`,
    DEVICES_STATISTICS: `${HOST}reseller/statistics/devices`,
    SUBRESELLERS_STATISTICS: `${HOST}reseller/statistics/reseller`,
    CREDITS_STATISTICS: `${HOST}reseller/statistics/total_credit`,
    APP_TOTAL_DEVICES_STATISTICS: `${HOST}reseller/statistics/app_total_devices`,
    DEVICES_BY_MODEL_STATISTICS: `${HOST}reseller/statistics/devices_by_model`,
    DEVICES_BY_COUNTRY_STATISTICS: `${HOST}reseller/statistics/devices_by_country`,
    PLAYLIST: `${HOST}reseller/devices/playlist`,
    FREE_DNS: `${HOST}reseller/free_dns`,
    FREE_DNS_STATISTICS_ALL: `${HOST}reseller/free_dns/statistics/all`,
    FREE_DNS_STATISTICS: `${HOST}reseller/free_dns/statistics`,
    NOTIFICATIONS: `${NOT_HOST}reseller/notifications`,
    FREE_DNS_DEVICES: `${HOST}reseller/devices/free_dns`,
};

export default URLS;
