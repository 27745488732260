import { useEffect, } from 'react';

import { Dropdown } from 'antd';

import IMAGES from '../config/images';

import useStore from '../hooks/use-store';

const list = ["en", "br", "ar"];

export default function LanguageDropdown() {
    const [language, setLanguage] = useStore('language');

    let renderList = () => {
        let langArr = list.map((lang, i) => {
            return language === lang ? null : {
                key: lang,
                label: <img
                    src={IMAGES[lang]}
                    alt={lang}
                    width={27}
                    onClick={() => {
                        setLanguage({ type: 'language/setLanguage', payload: lang });
                        localStorage.setItem('language', lang);
                    }} />
            }
        });

        return langArr || [];
    }

    useEffect(() => {
        let language = localStorage.getItem('language');

        if (language) {
            setLanguage({ type: 'language/setLanguage', payload: language })
        } else {
            localStorage.setItem('language', 'en');
        }
    }, []);

    return (
        <Dropdown
            trigger={['click']}
            placement='bottom'
            menu={{
                items: renderList()
            }}
            overlayClassName='lang-dropdown-overlay'
            className='lang-dropdown'
        >
            <img
                src={IMAGES[language]}
                alt={language}
                width={20}
            />
        </Dropdown>
    )
}
