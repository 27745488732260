import { useEffect, useState } from 'react';

import { Drawer, Flex, Select, Table, Alert } from 'antd';

import { REQUESTS } from '../../../api/requests';

import { HOST } from '../../../config';

import useResize from '../../../hooks/use-resize';

import apps from '../../../assets/ourApps.json';

export default function FreeDnsDevices({ open, onClose, current }) {
    const [selected, setSelected] = useState(apps[0].id);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [message, setMessage] = useState(null);
    const resize = useResize();

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                return (currentPage - 1) * limit + index + 1
            }
        },
        {
            title: 'Mac',
            dataIndex: 'mac',
            key: 'mac',
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
    ]

    const getFreDnsDevices = () => {
        setLoading(true);
        setMessage(null);

        const query = {
            page: currentPage,
            limit,
            multy_player_id: current.id,
            app_id: selected,
        };

        const callback = (res) => {
            const data = res.message;
            setLoading(false);
            setCurrentPage(data.currentPage);
            setTotal(data.count);
            setLimit(data.limit);

            const result = data.rows?.map((item, index) => {
                return {
                    ...item,
                    ...item.device
                }
            });

            setData(result);
        }

        const errorCallback = (err) => {
            setMessage(err);
            setLoading(false);
        };

        REQUESTS.FREE_DNS_DEVICES(query, callback, errorCallback)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current)
        setLimit(pagination.pageSize)
    }

    useEffect(() => {
        if (!open && !current) return;

        let timeoutId = setTimeout(() => {
            getFreDnsDevices();
        }, 500);

        return () => clearTimeout(timeoutId);

    }, [currentPage, limit, selected, open]);

    useEffect(() => {
        if (!open) {
            setSelected(apps[0].id);
            setData([]);
            setLoading(false);
            setCurrentPage(1);
            setTotal(0);
            setLimit(10);
            setMessage(null);
        }
    }, [open])

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title="Free DNS Devices"
            width={resize < 600 ? "95%" : 600}
            destroyOnClose={true}
        >
            <span>DNS: {current?.dns}</span>

            <Select
                style={{ width: "100%", margin: "24px 0" }}
                defaultValue={selected}
                onChange={setSelected}
            >
                {apps.map(app => (
                    <Select.Option key={app.id} value={app.id}>
                        <Flex align='center' gap={5}>
                            <img src={`${HOST}/${app.logo}`} width={23} /> {app.name}
                        </Flex>
                    </Select.Option>
                ))}
            </Select>
            {
                message ? <Alert
                    message={message}
                    type="warning"
                    showIcon
                /> :
                    <Table
                        dataSource={data}
                        columns={columns}
                        loading={loading}
                        onChange={handleTableChange}
                        pagination={{
                            current: currentPage,
                            total: total,
                            pageSize: limit,
                            showSizeChanger: true,
                        }}
                        size='small'
                        scroll={{ x: "max-content" }}
                    />
            }
        </Drawer>
    )
}
