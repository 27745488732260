import { useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import ICONS from '../../config/icons';

import useResize from '../../hooks/use-resize';

import styles from "../styles/layout.module.scss";

export default function AccountAvatar({ collapsed, setCollapsed, profile }) {
    const location = useLocation();

    const navigate = useNavigate();

    const width = useResize();

    return (
        <div
            className={classNames(
                styles['menu-item-profile'],
                { [styles['active']]: location.pathname == "/reseller/dashboard/profile" })}
            style={{
                paddingLeft: profile?.name?.length >= 18 ? 10 : 24,
                justifyContent: collapsed ? "center" : "flex-start"
            }}
            onClick={() => {
                if (width < 900) {
                    setCollapsed(true)
                }
                navigate("/reseller/dashboard/profile")
            }}>
            {collapsed ?
                ICONS.PROFILE :
                <div>
                    {ICONS.PROFILE} <div className={styles['name']}>
                        {profile?.name || "Profile"}
                    </div>
                </div>}
        </div>
    )
}
